<template>
  <div>
    <el-row style="padding-left: 10px">
      <el-button type="primary" @click="addOpen">添加项目参数</el-button>
    </el-row>
    <el-row style="margin-top: 20px;padding-left: 10px">
      <el-table
          :data="appProjectList"
          border
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          max-height="700"
          style="width: 99%;">
        <el-table-column label="标签名称" align="center" #default="scope">
          {{scope.row.projectsName}}
          <el-badge type="primary" value="系统默认" v-if="scope.row.isDefault === 1"/>
        </el-table-column>
        <el-table-column label="预约类型" align="center" #default="scope">
            {{scope.row.preAfterSale === 1 ? "售前预约" : scope.row.preAfterSale === 2 ? "售后预约" : ""}}
        </el-table-column>
        <el-table-column label="是否激活" align="center">
          <template #default="scope">
            <el-switch
                @change="isChange(scope.row)"
                v-model="scope.row.isValid"
                :active-value="1"
                :inactive-value="0"
                :disabled="scope.row.isDefault === 1"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="是否系统默认参数" v-if="isSuperAdmin" align="center" #default="scope">
          <el-switch
              @change="isDefaultChange(scope.row)"
              v-model="scope.row.isDefault"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button-group>
              <el-button type="primary" v-if="scope.row.isDefault !== 1" size="mini" @click="editOpen(scope.row)">修改</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <app-projects-add v-if="addState" :state="addState" @close="addClose"></app-projects-add>
    <app-projects-edit v-if="editState" :state="editState" :project="project" @close="editClose"></app-projects-edit>
  </div>
</template>

<script>
import appProjectsAdd from "@/pages/parameter/projects/app-projects-add.vue"
import appProjectsEdit from "@/pages/parameter/projects/app-projects-edit.vue"
export default {
  name: "app-projects-list",
  components: {
    appProjectsAdd,
    appProjectsEdit
  },
  created() {
    this.queryAppProjectList()
  },
  data() {
    return {
      appProjectList:[],
      total: 0,
      page: 1,
      limit: 100,
      addState: false,
      editState: false,
      project: {},
      isSuperAdmin: localStorage.getItem("tenantCrop") === '2a31c23a-c178-441614928053489'
    }
  },
  methods: {
    queryAppProjectList() {
      this.$axios({
        method: "GET",
        url: "/projects/projectsList",
        page: this.page,
        limit: this.limit,
        params: {
          tenantCrop: localStorage.getItem("tenantCrop"),
          projectsType: this.$projectsType.appoint
        }
      }).then(response => {
        this.appProjectList = response.data.data.list
        this.total = response.data.data.total
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryAppProjectList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryAppProjectList()
    },
    isChange(val) {
      this.$axios({
        method: "POST",
        url: "/projects/updateProject",
        params: {
          id: val.id,
          isValid: val.isValid,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response =>{
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        this.queryAppProjectList()
      })
    },
    addOpen() {
      this.addState = true
    },
    addClose() {
      this.addState = false
      this.queryAppProjectList()
    },
    editOpen(val) {
      this.project = val
      this.editState = true
    },
    editClose() {
      this.editState = false
      this.queryAppProjectList()
    },
    isDefaultChange(val) {
      this.$axios({
        method: 'post',
        url: "/projects/updateProject",
        params: {
          id: val.id,
          isDefault: val.isDefault,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        this.queryAppProjectList()
      })
    },
  },
}
</script>

<style scoped>

</style>